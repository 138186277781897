import {insertPhotoswipeHTML} from "./photoswipeHtml";

const defaults = {
    index: 0,
    history: false,
    focus: false,
    closeEl: true,
    captionEl: false,
    fullscreenEl:true,
    zoomEl: true,
    shareEl: true,
    counterEl: true,
    arrowEl: true,
    preloaderEl: true,
    showAnimationDuration: 0,
    hideAnimationDuration: 0,
    showHideOpacity: false,
    bgOpacity: 0.9
};

let imagesArray = [];

/**
 *
 * @param {HTMLImageElement} element
 * @return {HTMLElement}
 */
const addSearchItem = (element) => {
    const icon = document.createElement('i');
    icon.classList.add('icon-search');
    const parent = element.parentNode;
    parent.append(icon);
    return icon;
}

const openPhotoSwipe = async (imageIndex) => {
    const PhotoSwipe = await import("photoswipe");
    await import('photoswipe/dist/photoswipe.css')
    const PhotoSwipeUI = await import('photoswipe/dist/photoswipe-ui-default.min');
    await import('photoswipe/dist/default-skin/default-skin.css')

    let handler = document.querySelector("#swipeGallery");
    if (!handler) {
        insertPhotoswipeHTML();
        handler = document.querySelector("#swipeGallery");
    }

    defaults.index = imageIndex;
    const photoInstance = new PhotoSwipe.default(handler, PhotoSwipeUI.default, imagesArray, defaults);
    photoInstance.init();
}

export const photoSwipe = () => {
    import('./photswipe.scss');

    const images = document.querySelectorAll("img.gal");
    images.forEach((element, index) => {
        const src = element.getAttribute('src');
        const dataSrc = element.getAttribute('data-src');
        const source = src ? src : dataSrc;

        imagesArray.push({
            src: source,
            w: element.getAttribute('width'),
            h: element.getAttribute('height')
        });

        addSearchItem(element);

        const parentNode = element.parentNode ?? element;
        parentNode.addEventListener('click', (e) => {
            e.preventDefault();
            openPhotoSwipe(index);
        });
    });

}
