import {EInterceptionObserver} from "../EIntersectionObserver/EIntersectionObserver";

/** @var {{id: number, title: string, url: string}[]} newsIdHistoric History of news loaded **/
const newsIdHistoric = [];

/** @var {IntersectionObserver} mainIntersectionObserver IO responsible for monitoring the presence of the news loading beacon. **/
let mainIntersectionObserver;

/** @var {IntersectionObserver} h1IntersectionObserver IO that monitors the flow of the H1 **/
let h1IntersectionObserver;

/** @var {HTMLElement} endOfNewsElement News Loading Beacon element*/
let endOfNewsElement;

/** @var {({id: number, title: string, url: string}) => void | null} Callback fired when news is loaded and attached to DOM*/
let newsLoadedCallback;

/** @var {({id: number, title: string, url: string, is_new: boolean}) => void | null} Callback fired when viewports load another news*/
let newsActiveCallback;

const configureH1Intersection = () => {
    const options = {
        root: null, //viewport,
        rootMargin: "0px",
        threshold: 0,
    }

    const observerCallback = ([entry]) => {
        if (entry.boundingClientRect.top < 0) {
            const newsId = +entry.target.dataset.id;
            const dataIndex = newsIdHistoric.findIndex(item => item.id === newsId);

            let newsData = null;
            if (entry.isIntersecting) {
                // Entró por arriba en el viewport, luego el scroll tiene dirección ascendente. Está yendo hacia arriba
                // Reemplazar por la noticia ANTERIOR a la que indica el H1.
                if (dataIndex > 0) {
                    newsData = newsIdHistoric[dataIndex - 1];
                }
            } else {
                // Entró por abajo en el viewport, luego el scroll tiene dirección descendente. Está yendo hacia abajo
                // Reemplazar por la noticia que indica el H1
                if (dataIndex > -1) {
                    newsData = newsIdHistoric[dataIndex];
                }
            }

            if (newsData) {
                window.history.pushState({}, '', newsData.url);
                document.title = newsData.title;
                newsActiveCallback && newsActiveCallback(newsData);
                newsData.is_new = false;
            }
        }
    };

    h1IntersectionObserver = new IntersectionObserver(observerCallback, options);
}
const endOfNewsReached = async () => {
    const scrollNewsGenerator = endOfNewsElement.dataset.link;

    if (!scrollNewsGenerator) {
        return;
    }

    let data;
    try {
        const previousId = newsIdHistoric.map(item => item.id).join(',');
        const response = await fetch(scrollNewsGenerator + '?previous=' + previousId);
        data = await response.json();
        if (!('body' in data)) {
            throw new Error('Unknown response');
        }
        if (!data.body) {
            //Disable loading beacon (no H1)
            mainIntersectionObserver.disconnect();
            endOfNewsElement.remove();
            return;
        }
    } catch (e) {
        console.error(e);
        return;
    }

    const {id, body, title, url} = data;
    const div = document.createElement("div");
    div.innerHTML = body;
    endOfNewsElement.parentNode.insertBefore(div, endOfNewsElement);

    if (!h1IntersectionObserver) {
        configureH1Intersection();
    }

    const newsEntry = {
        id,
        title,
        url,
        is_new: true
    };

    newsIdHistoric.push(newsEntry);
    document.querySelectorAll('h1').forEach(item => h1IntersectionObserver.observe(item));
    newsLoadedCallback && newsLoadedCallback(newsEntry);
}

/**
 * @param {({id: number, title: string, url: string}) => void | null} newsLoadedCallbackFn
 * @param {({id: number, title: string, url: string, is_new: boolean}) => void | null} newsActiveCallbackFn
 */
export const newsInfiniteScroll = ({newsLoadedCallbackFn, newsActiveCallbackFn}) => {
    endOfNewsElement = document.querySelector('.end-of-news');
    if (!endOfNewsElement) {
        return;
    }
    const currentNewsId = +endOfNewsElement.dataset.id;
    if (!currentNewsId) {
        return;
    }
    newsIdHistoric.push({
        id: currentNewsId,
        title: document.title,
        url: window.location.href
    });

    newsLoadedCallback = newsLoadedCallbackFn;
    newsActiveCallback = newsActiveCallbackFn;

    mainIntersectionObserver = EInterceptionObserver(
        [endOfNewsElement],
        () => endOfNewsReached(),
        {keepObserving: true, rootMargin: '600px'}
    );

}

export const disableInfiniteScroll = () => {
    if (h1IntersectionObserver) {
        h1IntersectionObserver.disconnect();
    }

    if (mainIntersectionObserver) {
        mainIntersectionObserver.disconnect();
    }

    if (endOfNewsElement) {
        endOfNewsElement.remove();
    }
};