let skyWrap = null;
const sticky = document.querySelector('.advice-sticky');
const stickyClose = document.querySelector('.advice-sticky-close');

export const skies = (formatId) => {
    if (skyWrap) {
        return; // Already set
    }

    skyWrap = document.querySelector('.advice-sky-wrap');
    window.addEventListener("scroll", (event) => {
        if (!skyWrap) {
            return;
        }

        const scroll = window.scrollY;
        if (scroll < 239) {
            skyWrap.classList.remove('advice-sky-wrap--fixed');
        } else {
            skyWrap.classList.add('advice-sky-wrap--fixed');
        }
    }, {passive: true});
}

export const stickyWrap = () => {
    const onScroll = () => {
        if (!sticky || !stickyClose) {
            return;
        }

        const scroll = window.scrollY;
        if (scroll < 150) {
            sticky.classList.remove('advice-sticky--visible');
        } else {
            sticky.classList.add('advice-sticky--visible');
        }
    };

    stickyClose.addEventListener('click', function (e) {
        e.target.parentNode.classList.remove('advice-sticky--visible');
        window.removeEventListener('scroll', onScroll);
    });
    window.addEventListener("scroll", onScroll, {passive: true});
}