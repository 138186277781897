import './styles/general/index.scss';

import {configureAds, loadIntersticial, reloadAds} from '../marketing/publicidad';
import {skies, stickyWrap} from './javascript/assetAds'
import Marketing from './marketing';
import {newsInfiniteScroll} from "../modulos/infiniteScrolling/newsInfiniteScrolling";
import {checkUserConsentForPropose, waitUntilUserConsent, waitUserConsentForPropose} from "../marketing/consent";
import {sendVisitBeacon} from "../modulos/visitsAndPremiumStats/visitsAndPremiumStats";
import {sendPushInfiniteScroll} from "../marketing/gtm";
import {photoSwipe} from "../modulos/photoswipe/photoswipe";
import {sendContentGroups} from "../marketing/contentGroups";
import {loadCloudflarePixel} from "../marketing/cloudflare";
import {loadNewRelicPixel} from "../marketing/newrelic";
import {removeYoutubeFrameConsent} from "../modulos/youtube/youtubeConsent";
import {loadAdDoorPixel} from "../marketing/addoor";

// Configura la publicidad con los selectores de publicidad y actualiza
configureAds({
    adsSelector: 'div.advice,div.ad-holder>div.add',
    stickyHandler: skies,
    bottomHandler: stickyWrap,
}).then(_ => {
    reloadAds();
})

// Carga las funciones básicas del sitio
Marketing();

// Envía el aviso de noticia vista si existiera
sendVisitBeacon();

// Envía a GA los Grupos de contenido
sendContentGroups();

// Carga el zoom en las imágenes
photoSwipe();

let pagesViewedWithInfinite = 0;
const newsLoadedCallbackFn = () => {
    reloadAds();
};
const newsActiveCallbackFn = ({id, title, url, is_new}) => {
    if (is_new) {
        pagesViewedWithInfinite++;
        sendVisitBeacon();
        sendContentGroups();
        sendPushInfiniteScroll(pagesViewedWithInfinite);
    }
}
newsInfiniteScroll({newsLoadedCallbackFn, newsActiveCallbackFn});

loadAdDoorPixel()
    .catch(e => console.error(`addoor error: ${e}`))


checkUserConsentForPropose()
    .then(_ => {
        loadIntersticial();

        // Ads
        configureAds({
            adsSelector: 'div.advice,div.ad-holder>div.add',
            stickyHandler: skies,
            bottomHandler: stickyWrap,
        }).then(_ => {
            reloadAds();
        });
    });


waitUserConsentForPropose('cookies', () => {
    // New Relic pixel
    loadNewRelicPixel();

    //Cloudflare script
    loadCloudflarePixel();

    //Eliminar el paso intermedio de Consent en vídeos de Youtube
    removeYoutubeFrameConsent();
});