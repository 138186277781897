const burgerMenu = () => {
    const menuBtn = document.querySelector('.header__hamburger');
    const nav = document.querySelector('nav.nav');

    if (!menuBtn || !nav) {
        console.warn('No hamburger');
        return;
    }

    menuBtn.addEventListener('click', (e) => {
        e.preventDefault();
        nav.classList.toggle('opened');
    })
}

const cajaLogin = () => {
    const link = document.querySelector('.header__acess--login');
    const url = link.dataset.cajalogin;
    fetch(url)
        .then(response => response.json())
        .then(response => {
            if (response && response.user) {
                link.innerHTML = '<span>'+response.user+'</span>';
                link.removeAttribute('href');
            }
        })
        .catch(err => console.warn(err));
}

export default function () {
    document.addEventListener('DOMContentLoaded', () => {
        cajaLogin();
        burgerMenu();
    });
}